import { addLoader, removeLoader } from './components/loading-dots';

const wrappers = document.querySelectorAll('.tab-pane-wrapper');

/**
 * Gets content for the pane
 * @param {Element} tab
 * @param {Element} pane
 */
const getContent = (tab, pane) => {
    const url = tab.getAttribute('data-source');
    // add loading animation
    addLoader(pane, { inner: true });
    if (url) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'json';
        xhr.onload = () => {
            if (xhr.status === 200) {
                pane.innerHTML += xhr.response.content;
                // remove loading animation
                removeLoader();
            }
        };
        xhr.send();
    }
};

/**
 * Activates pane
 * @param {Element} tab
 */
const activatePane = (tab) => {
    const pane = document.getElementById(tab.getAttribute('aria-controls'));
    if (pane) pane.classList.add('active');
    // do ajax call if there is no content in a pane and a tab has data-source attribute
    if (!pane.innerHTML && tab.hasAttribute('data-source')) {
        getContent(tab, pane);
    }
};

/**
 * Activates tab
 * @param {Element} tab
 */
const activateTab = (tab) => {
    tab.setAttribute('aria-selected', 'true');
};

/**
 * Deactivates pane
 * @param {Element} deactivatedTab
 */
const deactivatePane = (deactivatedTab) => {
    const pane = document.getElementById(deactivatedTab.getAttribute('aria-controls'));
    if (pane) pane.classList.remove('active');
};

/**
 * Deactivates tab and return it
 * @param {Elemenr} wrapper
 * @returns {Element}
 */
const deactivateTab = (wrapper) => {
    const tab = wrapper.querySelector('[role="tab"][aria-selected="true"]');
    if (tab) tab.setAttribute('aria-selected', 'false');
    return tab;
};

/**
 * Toggle between tabs
 * @param {Element} tab
 * @param {Element} wrapper
 */
const toggleTab = (tab, wrapper) => {
    const deactivatedTab = deactivateTab(wrapper);
    deactivatePane(deactivatedTab, wrapper);
    activateTab(tab);
    activatePane(tab);
};

/**
 * Clcik handler for tab links
 * @param {Event} e
 * @param {Element} wrapper
 */
const handleClick = (e, wrapper) => {
    e.preventDefault();
    const tab = e.currentTarget;
    toggleTab(tab, wrapper);
};

/**
 * Inits tabs inside wrapper
 * @param {Element} wrapper
 */
const init = (wrapper) => {
    const tabs = wrapper.querySelectorAll('[role="tab"]');
    tabs.forEach((tab) => {
        tab.addEventListener('click', (e) => {
            handleClick(e, wrapper);
        });
    });
};

wrappers.forEach(init);
